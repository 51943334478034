/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import slug from "slug"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class ArticleTemplate extends React.Component {
  render() {
    const article = this.props.data.article
    const siteTitle = this.props.data.site.siteMetadata.title
    const collection = article.fields.collection
    const section = article.fields.section

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={article.frontmatter.title}
          description={article.frontmatter.description || article.excerpt}
        />
        <p className="mb-2">
          <Link to="/" className="text-xs text-gray-700 hover:text-blue-800">
            {this.props.data.site.siteMetadata.texts.allCollectionsText}
          </Link>
          {" "}
          <span className="text-xs text-gray-500">
            &rsaquo;
          </span>
          {" "}
          {collection && (
            <React.Fragment>
              <Link to={collection.fields.slug} className="text-xs text-gray-700 hover:text-blue-800">
                {collection.title}
              </Link>
              {" "}
              <span className="text-xs text-gray-500">
                &rsaquo;
            </span>
              {" "}
              {section && (
                <>
                  <Link to={collection.fields.slug + "#" + slug(section.id)} className="text-xs text-gray-700 hover:text-blue-800">
                    {section.title}
                  </Link>
                  {" "}
                  <span className="text-xs text-gray-500">
                    &rsaquo;
                </span>
                  {" "}
                </>
              )}
            </React.Fragment>
          )}
          <span sx={{ color: "breadcrumbTextColor", fontSize: 1 }}>
            {article.frontmatter.title}
          </span>
        </p>

        <div className="px-8 sm:px-16 py-8"
          sx={{
            backgroundColor: "paperBackgroundColor",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "paperBorderColor",
            borderRadius: 3,
            mb: 4,
            position: "relative",
            zIndex: "3",
            textDecoration: "none",
            overflow: "hidden",
            width: "100%",
            display: "block",
            outline: "none",
            boxShadow: "0 3px 8px 0 rgba(0,0,0,0.03)",
            transition:
              "border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear, transform .15s linear, box-shadow .15s linear",
            color: "articleTextColor",
          }}>
          <article className="prose">
            <header>
              <div className="mt-4">
                <h1>
                  {article.frontmatter.title}
                </h1>
              </div>
              <div className="-mt-2">
                <p className="text-base leading-6 text-gray-400">
                  {article.frontmatter.description}
                </p>
              </div>

              {article.frontmatter.author && (
                <div className="flex mt-6">
                  <div className="flex items-center">
                    <Image className="m-0-important"
                      fixed={
                        article.frontmatter.author.avatar.childImageSharp.fixed
                      }
                      alt={article.frontmatter.author.name}
                      style={{
                        marginRight: rhythm(1 / 2),
                        marginBottom: 0,
                        width: 40,
                        height: 40,
                        borderRadius: `100%`,
                      }}
                      imgStyle={{
                        borderRadius: `50%`,
                      }}
                    />
                  </div>
                  <div className="flex items-center text-gray-500 text-xs leading-4">
                    <div>
                      <div>
                        {this.props.data.site.siteMetadata.texts.writtenByText}{" "}
                        <span className="text-gray-800">
                          {article.frontmatter.author.name}
                        </span>
                      </div>
                      {article.frontmatter.modifiedDate ? (
                        <div
                          sx={{
                            display: `block`,
                          }}
                        >
                          <span
                            title={new Date(
                              article.frontmatter.modifiedDate
                            ).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}
                          >
                            {
                              this.props.data.site.siteMetadata.texts
                                .lastModifiedText
                            }{" "}
                            {new Date(
                              article.frontmatter.modifiedDate
                            ).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}
                          </span>
                        </div>
                      ) : article.frontmatter.date ? (
                        <div
                          sx={{
                            display: `block`,
                          }}
                        >
                          <span
                            title={new Date(
                              article.frontmatter.date
                            ).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}
                          >
                            {
                              this.props.data.site.siteMetadata.texts
                                .publishedOnText
                            }{" "}
                            {new Date(
                              article.frontmatter.date
                            ).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </header>
            <hr sx={{ background: "hsla(0,0%,0%,0.05)" }} />
            <section
              sx={{ pb: 4 }}
              dangerouslySetInnerHTML={{ __html: article.html }}
            />
          </article>
        </div>

      </Layout >
    )
  }
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        texts {
          writtenByText
          allCollectionsText
          lastModifiedText
          publishedOnText
        }
        language
      }
    }
    article: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        section {
          id
          title
        }
        collection {
          id
          title
          fields {
            slug
          }
        }
      }
      frontmatter {
        title
        date
        modifiedDate
        description
        author {
          id
          name
          avatar {
            childImageSharp {
              fixed(width: 100, height: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
